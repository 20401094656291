@import url('//cdn.datatables.net/1.10.20/css/dataTables.bootstrap4.min.css');

@import url('//cdn.datatables.net/buttons/1.5.6/css/buttons.dataTables.min.css');

@import url('//cdn.datatables.net/fixedcolumns/3.3.0/css/fixedColumns.bootstrap4.min.css');

.DTFC_LeftBodyLiner {
    overflow-x: hidden;
}
th {
    white-space: wrap;
    max-width: 100%;
}
button.dt-button.active {
    background: greenyellow !important;

}
// td{
//     white-space: pre;
// }
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{
    vertical-align: middle;
}
    
div.dt-button-collection {
    overflow: visible;
}

.dt-button.buttons-columnVisibility{
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    transition: all 0.3s ease;
}

.dt-button.buttons-columnVisibility:hover{
    z-index: 999;
    min-width: 250px;
    width: auto;
    transform: scale(1.03);
    box-shadow: 0 3px 10px #323232 !important;
    outline: 1px solid #e80606;
}

.dt-button.buttons-columnVisibility span{
    transition: all 0.3s ease;
}
