/* Conflicting Classes reset to initial: */
.outline {
  outline-style: initial !important;
}

.collapse {
  visibility: initial !important;
}

:root {
  --top-menu-height: 3.8rem;
  --color-gray: #2b313b;
  --color-blue: #6383a8;
  --color-green: #9ec54d;
  --color-red: #c41f6d;
  --color-bc-blue: #0101bb;
  --color-bc-green: #217f6d;
  --color-bc-red: #c4166d;
}

/* Form Collapsible and card-header BG override. */
.pink .card-header {
  background-color: pink;
}
.blue .card-header {
  background-color: var(--color-blue);
}
.green .card-header {
  background-color: var(--color-green);
}

/* Form Collapsible and card-header theme for new Build Change Color Branding */
.rheat-container .pink .card-header {
  background-color: var(--color-bc-red) !important;
}
.rheat-container .blue .card-header {
  background-color: var(--color-bc-blue) !important;
}
.rheat-container .green .card-header {
  background-color: var(--color-bc-green) !important;
}
.rheat-container .formio-component-button button[type="submit"] {
  background-color: var(--color-bc-blue) !important;
  padding: 0.5rem 1.5rem !important;
  margin-top: 8px;
}
.rheat-container .formio-component-button button[type="submit"]:hover {
  opacity: 0.8;
}

.cell-align-left:first-child {
  width: 20%;
}

.cell-align-left:nth-of-type(2) {
  width: 80%;
}

body {
  background-color: #f9fafb !important;
}

@font-face {
  font-family: pdfreport;
  src: url(GERALDINE.ttf);
}

.ui.progress {
  height: initial !important;
  overflow: initial !important;
}

.ui.progress .bar > .progress {
  background-color: initial !important;
  overflow: initial !important;
}

.first-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding-top: 200px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 1.1em;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: var(--color-blue) !important;
}

.ui.styled.accordion .title {
  color: var(--color-blue) !important;
}

.custom-accordion-container .ui.accordion .title .dropdown.icon {
  transform: translate(-15px, 7px) !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.color-blue {
  color: var(--color-blue) !important;
}

.color-green {
  color: var(--color-green) !important;
}

.bg-blue {
  background-color: var(--color-blue) !important;
  color: white !important;
}

.bg-green {
  background-color: var(--color-green) !important;
  color: white !important;
}

.ui.blue.label,
.ui.blue.labels .label {
  background-color: var(--color-blue) !important;
  border-color: var(--color-blue) !important;
  color: #fff !important;
}

.field .required.field.formLabel label {
  font-weight: normal !important;
}

.app-card-org {
  background-color: #ffffff;
  padding: 20px;
  margin: 15px 0;
  border-radius: 5px;
  -webkit-box-shadow: -2px 2px 10px -2px rgba(117, 106, 117, 1);
  -moz-box-shadow: -2px 2px 10px -2px rgba(117, 106, 117, 1);
  box-shadow: -2px 2px 10px -2px rgba(117, 106, 117, 1);
}

.view-org-btn {
  display: flex;
  justify-content: center;
}

.backto-module {
  background-color: var(--color-red) !important;
  color: #fff !important;
}

.ui.breadcrumb a {
  color: var(--color-blue) !important;
}

.mcq-options label {
  font-size: 1rem;
  width: 50vw;
  padding: 10px 40px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px -1px rgba(117, 106, 117, 1);
  margin: 10px 0;
  position: relative;
}

.finished-congratulations {
  position: relative;
  height: 93vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: var(--color-blue);
}

.finished-congratulations-pre {
  position: relative;
  height: 85vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: var(--color-blue);
}

.mcq-options label input {
  cursor: pointer;
  zoom: 1.3;
  transform: translate(0px, 2px);
  position: absolute;
  left: 8px;
}

.mcq-options label .custom-checkbox {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-radius: 5px;
  border: 2px solid var(--color-green);
  transform: translateY(-50%);
  transition: all 0.2s ease-out;
}

.mcq-options label .custom-checkbox::after {
  position: absolute;
  content: "";
  left: 12px;
  top: 12px;
  height: 0;
  width: 0;
  border-radius: 5px;
  border: solid var(--color-green);
  border-width: 0 3px 3px 0;
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.2s ease-out;
}
.training-video,
.training-pdf {
  width: 75vw;
}

.mcq-options label input:checked ~ .custom-checkbox::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  left: 4px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: solid var(--color-green);
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}

.ui.breadcrumb .active.section {
  color: var(--color-green);
}

.version {
  padding: 0 10px;
  border-right: 1px solid rgb(165, 165, 165);
}

.training-breadcrumb-module {
  padding: 6px 50px 6px 15px;
  display: inline-block;
  clip-path: polygon(0 0, 88% 0%, 100% 100%, 0% 100%);
}

.training-breadcrumb-lesson {
  padding: 6px 50px 6px 30px;
  display: inline-block;
  clip-path: polygon(0 0, 88% 0%, 100% 100%, 11% 100%);
  margin-left: -10px;
}

.lesson-title {
  background-color: gray;
  border-left: 8px solid darkslategrey;
  border-radius: 4px;
  margin-bottom: 30px;
  width: 70%;
}

.lesson-title h4 {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 10px 30px;
  margin-top: 0;
  text-align: center;
}

.training-module {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  cursor: pointer;
}

.module-title {
  background-color: var(--color-blue);
  border-left: 8px solid var(--color-green);
  border-radius: 4px;
  margin-bottom: 30px;
  width: 70%;
}

.module-title h4 {
  font-size: 1.2rem;
  font-weight: 700;
  padding: 10px 30px;
  margin-top: 0;
  text-align: center;
  color: #ffffff;
}

.section-content {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  min-height: 33vh;
}

.section-last {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
}

.circle-percentage span {
  font-weight: 600;
  color: var(--color-blue);
  letter-spacing: 5px;
  font-size: 0.8rem;
}

.section-last button {
  padding: 10px 40px !important;
}

.ui.multiple.dropdown > .label {
  color: var(--color-blue);
  font-size: 0.8em !important;
  box-shadow: none !important;
}

.ui.form .field > label {
  font-weight: 400 !important;
}

.modal-customized {
  position: relative !important;
  /* padding: 20px; */
}

.ui.search {
  font-size: 0.8rem;
}

.filters {
  display: flex;
  gap: 1px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  color: white;
}

.filters div {
  width: 13vw;
}

.filters .filter-effect {
  width: 35px;
  height: 35px;
  cursor: pointer;
  border: none;
  background-color: var(--color-red);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 16px;
}

.filters .filter-effect i.icon {
  margin: 0 !important;
}

.filters-options {
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  padding: 10px;
  max-height: 70vh;
  border-radius: 5px;
  font-size: 0.8rem;
  overflow-y: scroll;
  background-color: white;
  animation: slide-out 0.2s;
}

@keyframes slide-out {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.filters-options > p {
  text-align: center;
}

.demographic-details {
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  padding: 10px;
  max-height: 70vh;
  border-radius: 5px;
  font-size: 0.8rem;
  overflow-y: scroll;
  background-color: white;
  animation: slide-in 0.2s;
}

.export-button {
  font-size: 0.9rem !important;
}

.filters-title {
  background-color: var(--color-green);
  color: white;
  box-shadow: -2px 2px 10px -2px rgba(117, 106, 117, 1);
  width: 90%;
  padding: 5px 10px;
  margin: 10px auto;
}

.filters-options > div {
  width: 90%;
  margin: 0 10px;
}

.range > input {
  width: 20px;
}

.filters div button {
  font-weight: 500 !important;
  font-size: 0.8rem !important;
  /* color: whitesmoke !important; */
}

.download-data {
  background-color: white !important;
  color: var(--color-blue) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.download-data:hover {
  color: white !important;
  background-color: var(--color-blue) !important;
}

.new-report {
  background-color: white !important;
  color: var(--color-green) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.new-report:hover {
  color: white !important;
  background-color: var(--color-green) !important;
}

.org-dashboard-content {
  display: flex;
  margin: 15px 0;
  flex-wrap: wrap;
  gap: 15px;
}
.demo-project-list {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 10px 0;
  flex-wrap: wrap;
  /* cursor: pointer; */
}

.demo-project-list h4 {
  color: var(--color-blue);
  border-bottom: 2px solid var(--color-green);
}

.dashboard-stats {
  display: flex;
  justify-content: space-around;
  text-align: center;
  padding: 2rem 0;
  flex-wrap: wrap;
  margin-top: 20px;
}

.dashboard-stats > div {
  width: 200px;
  padding: 10px;
}

.dashboard-stats-label {
  display: flex;
  justify-content: space-between;
  margin: 0 10px;
  font-weight: bold;
}

.dashboard-side {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-arounds;
}

.side-title {
  /* text-align: center; */
  font-weight: bold;
}

.side-content {
  font-size: 0.8rem;
}

.side-content p {
  margin-bottom: 0;
}

.languageDiv {
  width: 300px;
  background-color: #fff;
  position: absolute;
  margin: 10px;
  border-radius: 10px;
  padding-top: 15px;
  right: 0;
  min-height: 70px;
  height: auto;
  -webkit-box-shadow: -2px 2px 21px -2px rgba(117, 106, 117, 1);
  -moz-box-shadow: -2px 2px 21px -2px rgba(117, 106, 117, 1);
  box-shadow: -2px 2px 21px -2px rgba(117, 106, 117, 1);
}
.languageDiv.mobile {
  right: -125px;
}

.languageDiv ul {
  list-style-type: none;
}

.languageDiv ul li {
  margin-bottom: 10px;
  cursor: pointer;
}

.top-menu {
  width: 100%;
  height: var(--top-menu-height);
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  background-color: white;
  border-bottom: 1px solid var(--color-green);
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important; */
}

.logo-title {
  width: 43%;
  align-items: center;
  display: flex;
  height: 3rem;
  cursor: pointer;
}

.logo-icon {
  width: 23%;
}

.lines {
  border-top: 7px solid #6384a9;
  border-bottom: 7px solid #9ec74e;
}

.button-primary {
  background-color: #6384a9 !important;
  color: white !important;
}

.moduleDivider {
  background-color: #9ec74e;
  height: 1px;
  width: 90%;
  border-radius: 25%;
}

.grid1 {
  width: 100%;
  display: grid;
  grid-template-rows: var(--top-menu-height) auto;
  grid-template-areas: "topmenu" "maincontent";
  min-height: 100vh;
}

.topmenu {
  grid-area: topmenu;
  z-index: 100;
}

.upper-menu {
  background-color: #1b4a88 !important;
  border-radius: 5px !important;
}

.main-content {
  grid-area: maincontent;
}

.copyright {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0 !important;
}

.parent {
  display: inline-flex;
}

.side {
  transition: width 0.3s;
  width: 16em !important;
  overflow: hidden;
  margin-top: var(--top-menu-height) !important;
  z-index: 99;
  height: 95vh !important;
  font-size: 13px !important;
  padding: 10px 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 7px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.small-side {
  width: 4em !important;
  flex-basis: 4em;
  padding: 0;
}

.small-side-mobile {
  width: 0em !important;
  flex-basis: 0em;
  padding: 0;
}

.leaflet-popup-content {
  width: 200px !important;
  height: auto;
  overflow-y: auto;
}

.app-card {
  border-radius: 0;
  box-shadow: none;
}

.ui.vertical.menu .active.item {
  background: #9ec74e !important;
  color: white;
  border-radius: 10px !important;
}

.bg-purple {
  background-color: purple;
}

.statlabel {
  font-size: 10px !important;
  color: grey !important;
}

.seismic-container {
  margin: 0;
  padding: 0;
  outline: 4px solid #eee;
  border-radius: 7px;
  box-shadow: 0 4px 10px 2px #7a7a7a;
  transition: all 0.3s ease;
}

.seismic-container:hover {
  box-shadow: 0 3px 8px 2px #7a7a7a;
}

.myFrame {
  width: 100%;
  height: 85vh;
}

.content1 {
  position: absolute;
  right: 0;
  transition: all 0.3s;
  padding: 0 10px;
  width: calc(100% - 15em);
}

.small-content1 {
  padding: 0 10px;
  width: calc(100% - 4em);
}

@media (max-width: 767px) {
  /* On small screens, the nav menu spans the full width of the screen. Leave a space for it. */
  .small-side {
    width: 0em !important;
    flex-basis: 0em;
  }
  .small-content1 {
    padding: 10px;
    width: 100% !important;
  }
  .filters div {
    width: 100%;
  }
}

.display-inline {
  display: inline-flex;
  align-items: center;
}

.no-border::before {
  display: none;
}

.top-menu i.icon {
  margin: 0 !important;
}

.drop-left-padding {
  padding-left: 0 !important;
}

.label-on-corner {
  top: 0.8em !important;
  left: 78% !important;
}

.overview span {
  position: relative;
  text-overflow: ellipsis;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.overview span[title]::before {
  content: attr(title);
}

.overview span:hover {
  width: auto;
}

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }

  .fixed.side.menu {
    display: none !important;
  }

  .small-content1 {
    width: 100% !important;
  }

  .topmenu {
    display: none !important;
  }

  .grid1 {
    grid-template-areas: "maincontent" !important;
  }
}

.my-custom-scrollbar {
  min-height: 5vh;
  height: auto;
}

@media print and (color) {
  .my-custom-scrollbar {
    height: auto !important;
  }
}

.my-custom-header {
  display: none;
  visibility: hidden;
}

.step-form-accordion-button {
  background-color: var(--color-blue) !important;
  border-color: var(--color-blue) !important;
  color: white !important;
}

@media print and (color) {
  .my-custom-header {
    display: flex;
    visibility: visible !important;
  }
  .step-form {
    display: none;
    visibility: hidden;
  }
  .step-form-accordion-button {
    background-color: transparent !important;
    color: black !important;
    outline: 2px solid var(--color-blue) !important;
  }
}

.clearButton {
  /* background-color: var(--color-red) !important;
  color: white !important; */
  background-color: white !important;
  color: var(--color-red) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  margin: 4px 4px !important;
}

.clearButton:hover {
  background-color: var(--color-red) !important;
  color: white !important;
  /* background-color: rgb(236, 234, 234) !important; */
}

.editButton {
  background-color: white !important;
  color: var(--color-gray) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  margin: 4px 4px !important;
}

.deleteButton {
  background-color: white !important;
  color: var(--color-red) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  margin: 4px 4px !important;
}

.submitButton:hover {
  background-color: var(--color-green) !important;
  color: white !important;
}
.submitButton {
  background-color: white !important;
  color: var(--color-green) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  margin: 4px 4px !important;
}

.submitButton.ui.disabled.active.loading.button,
.submitButton.ui.disabled.loading.button,
.submitButton.ui.disabled.loading.button:hover,
.submitButton.loading.disabled {
  opacity: 1 !important;
  background-color: var(--color-green) !important;
  color: whit !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.createButton:hover {
  background-color: var(--color-blue) !important;
  color: white !important;
}
.createButton {
  background-color: white !important;
  color: var(--color-blue) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  margin: 4px 4px !important;
}

.createButton.ui.disabled.active.loading.button,
.createButton.ui.disabled.loading.button,
.createButton.ui.disabled.loading.button:hover,
.createButton.loading.disabled {
  opacity: 1 !important;
  background-color: var(--color-blue) !important;
  color: whit !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.importButton:hover {
  background-color: var(--color-green) !important;
  color: white !important;
}
.importButton {
  background-color: white !important;
  color: var(--color-green) !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  margin: 4px 4px !important;
}

.modalHeader {
  font-size: 1.3rem !important;
}

.modalContent label,
.modalActions button {
  font-size: 1rem !important;
}

/* report form view */

.project-report-title {
  text-align: center;
}

.project-title-formbuilder {
  text-align: center;
}

.formbuilder-users {
  margin: 20px 0;
  display: flex;
  justify-content: space-around;
}

.main-title {
  font-size: 1.8rem;
  margin: 10px 0;
  color: rgb(24, 24, 24);
  text-align: center;
  border-bottom: 1px solid var(--color-gray);
}

.sub-title {
  font-size: 0.9rem;
  color: gray;
}

.information {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: space-around;
}

.information-title {
  position: relative;
  font-weight: 700;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.information-title::before {
  position: absolute;
  content: "";
  height: 2px;
  width: 40px;
  bottom: -5px;
  left: 0;
  border: 1px solid transparent;
  border-radius: 20px;
  background-color: var(--color-green);
}

.information-value {
  color: #a7a7a7;
  padding: 4px 4px;
  border-radius: 3px;
  font-size: 0.8rem;
}

/* formbuilder report table */

.table-data {
  width: 100%;
  text-align: left;
  border: transparent;
  margin: 20px auto;
}

.rows {
  display: flex;
  margin: 20px auto;
  border: 1px black;
  border-style: solid;
  border-radius: 4px;
}

.row-head {
  width: 30%;

  padding: 7px 15px;
}
.row-body {
  position: relative;
  width: 70%;
  padding: 7px 15px;
}

.row-body::before {
  position: absolute;
  content: "";
  height: 60%;
  width: 1px;
  top: 20%;
  bottom: 20%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.152);
}

.rows:nth-child(odd) {
  background-color: #6383a817;
}

.title-primary {
  background-color: var(--color-blue);
  border-left: 8px solid var(--color-green);
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.title-primary h4 {
  font-size: 1.3rem;
  font-weight: 700;
  padding: 5px 15px;
  margin-top: 0;
  color: #ffffff;
}

.title-secondary {
  background-color: var(--color-blue);
  border-left: 8px solid var(--color-green);
  border-radius: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.title-secondary h5 {
  font-size: 1.2rem;
  font-weight: 900;
  padding: 5px 15px;
  color: #ffffff;
}

.title-tertiary {
  /* background-color: rgb(100, 100, 100); */
  background-color: var(--color-blue);
  border-left: 8px solid var(--yellow);
  border-radius: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.title-tertiary h5 {
  font-size: 1.2rem;
  font-weight: 900;
  padding: 5px 15px;
  color: #ffffff;
}

.qucik-view {
  background-color: var(--color-blue);
  color: white;
  font-size: 0.7rem;
  padding: 5px 10px;
  border: none;
  border-radius: 2px;
  outline: none;
}
.iconSize {
  font-size: 1.1rem !important;
}
.clearButtonIconSize {
  font-size: 1.2rem !important;
}

/*training components styles */

.question-container {
  width: 50vw;
  display: flex;
  justify-content: space-between;
}

.image-tag {
  align-self: center;
}

.badge-info {
  background-color: transparent !important;
  color: var(--color-blue) !important;
}

.badge-danger {
  background-color: transparent !important;
  color: var(--color-red) !important;
}

.answer-image-tag {
  position: absolute;
  right: 4rem;
}

/* advance print */

.print-tab-menu {
  box-sizing: border-box;
  height: 83vh;
}

.print-tab-menu .active.item {
  background-color: var(--color-green) !important;
  color: white !important;
}

.print-tab-menu textarea {
  border-color: #6383a869;
  padding: 0.5rem;
}

.render-results {
  margin-left: 1rem;
  width: 100%;
}

.render-results .result {
  box-sizing: border-box;
  padding: 1rem;
  margin-top: 1rem;
  background-color: white;
  height: 100vh;
  box-shadow: 0px 0px 5px 0px rgba(128, 128, 128, 0.378);
}

.result .footer {
  position: absolute;
  bottom: 1rem;
  color: gray;
}

.custom-input {
  border: 1px solid var(--color-green);
  padding: 0.5rem 1rem;
}

.react-codemirror2 {
  flex: 1 1 auto;
  margin-top: 0;
  height: 100%;
  position: relative;
}
.code-editor1 .CodeMirror {
  height: auto !important ;
  border: 1px solid #eee;
}

.code-editor1 .CodeMirror-scroll {
  max-height: 80vh !important;
}

.code-editor .CodeMirror {
  height: 80vh !important ;
}

.ui.attached.tabular.menu {
  padding: 0 1rem;
}

.ui.attached.tabular.menu + .ui.bottom.attached.segment.active.tab {
  padding: 0 !important;
}

.ui.tabular.menu .active.item {
  background: none #dddddd !important;
}

.gridColumnNoPaddingRight {
  padding-right: 0 !important;
}

.gridColumnNoPaddingLeft {
  padding-left: 0 !important;
}

.report-side-menu {
  height: 100%;
  background-color: #333333;
}

.report-side-menu > div {
  padding: 1rem 2.1rem;
}

.report-side-menu i {
  color: white;
  cursor: pointer;
}

.afterFullScreen {
  padding: 1rem;
}

.fullscreen-enabled .afterFullScreen {
  background-color: #f9fafb;
  padding: 0;
}

.formfields {
  padding: 1rem;
  display: none !important;
}

.formfields.active {
  display: block !important;
}

.formfields input {
  outline: none;
  border: 1px solid gray;
  border-radius: 0.3rem;
  padding: 0.3rem;
}

.formfields .tags {
  list-style-type: none;
  padding-inline-start: 0;
}

.formfields .tags li {
  margin: 0.5rem 0;
  background-color: #d6d6d6;
  width: fit-content;
  padding: 0.2rem;
  border-radius: 0.3rem;
}

.formbuilder-header {
  font-size: 1.2rem;
  background-color: #d6d6d6;
  width: 100%;
  display: flex;
  justify-content: space-between;
  display: none;
}

.fullscreen-enabled .formbuilder-header {
  display: flex;
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 0;
}

/* Date Picker */
@keyframes slowAnimate {
  0% {
    opacity: 0;
  }
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ui.active.modal {
  overflow: visible !important;
}

.ui.red.button {
  background-color: var(--color-red) !important;
}

.stats-button-red {
  color: var(--color-red) !important;
  background-color: transparent !important;
  border: 1px solid var(--color-red) !important;
}

.stats-button-blue {
  color: var(--color-blue) !important;
  background-color: transparent !important;
  border: 1px solid var(--color-blue) !important;
}

.ui.positive.button,
.ui.green.button {
  background-color: var(--color-green) !important;
}

.ui.inverted.red.button,
.ui.inverted.green.button {
  color: white !important;
}

.btn-primary,
.badge-primary,
.btn-info {
  background-color: var(--color-blue) !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.badge-success {
  background-color: var(--color-green) !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.btn-secondary {
  background-color: var(--color-red) !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.data-share {
  background-color: var(--color-green) !important;
  color: white !important;
}

.data-not-share {
  background-color: var(--color-blue) !important;
  color: white !important;
}

.ui.list .list > .item a.header,
.ui.list > .item a.header {
  color: var(--color-blue) !important;
}

.nav-tabs .nav-link {
  color: var(--color-blue) !important;
}

/* a {
  color: var(--color-blue) !important;
} */

.btn-success {
  background-color: var(--color-green) !important;
  border-color: var(--color-green) !important;
}

.btn-danger {
  background-color: var(--color-red) !important;
  border-color: var(--color-red) !important;
}

.wizard-page-label {
  padding: 7px;
  border-radius: 10px !important;
}

.ql-editor {
  min-height: 150px !important;
  max-height: 300px;
}
@media screen and (max-width: 812px) {
  .filters-options {
    flex: none;
    width: 100%;
  }
}

.formbuilder-buttons {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  margin: 4px 4px !important;
}

.dashboard-map {
  height: 70vh !important;
  z-index: 2 !important;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
}

.progress {
  height: 0.5rem !important;
}

.glass {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 191, 255, 0.075);
  box-shadow: rgba(0, 0, 0, 0.3) 2px 8px 8px;
  border: 2px rgba(255, 255, 255, 0.4) solid;
  border-bottom: 2px rgba(40, 40, 40, 0.35) solid;
  border-right: 2px rgba(40, 40, 40, 0.35) solid;
}

.d-app-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.d-app-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 340px;
  padding: 20px;
}

.d-app-buttons {
  display: flex;
  justify-content: space-around;
}

.d-app-buttons button {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px !important;
  margin: 4px 4px !important;
  width: 200px;
}

.d-app-buttons button:nth-child(1) {
  background-color: white !important;
  color: var(--color-blue) !important;
}

.d-app-buttons button:nth-child(2) {
  background-color: white !important;
  color: var(--color-red) !important;
}

.d-app-buttons button:nth-child(1):hover {
  background-color: var(--color-blue) !important;
  color: white !important;
}

.d-app-buttons button:nth-child(2):hover {
  background-color: var(--color-red) !important;
  color: white !important;
}

.d-org-card {
  width: 245px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  cursor: pointer;
}

.d-org-card > div > p {
  font-weight: bold;
}

.d-org-card:hover {
  color: var(--color-red);
}

.page-item.active .page-link {
  border-color: var(--color-blue) !important;
  background-color: var(--color-blue) !important;
}

.page-item .page-link {
  color: var(--color-blue);
}

#react-jitsi-container {
  width: 1040px !important;
  height: 585px !important;
}

.watermark {
  background-image: url(https://buildchange.org/app/uploads/2020/12/BuildChange_Hex_Final_wide.png) !important;
  max-width: 182px !important;
  max-height: 119px !important;
}

.data_container {
  max-lines: 25;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fab {
  width: 50px;
  height: 50px;
  /* background-color: red; */
  border-radius: 50%;
  box-shadow: 0 6px 10px 0 #666;
  transition: all 0.1s ease-in-out;

  font-size: 50px;
  /* color: white; */
  text-align: center;
  line-height: 70px;

  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
}

.fab:hover {
  box-shadow: 0 6px 14px 0 #666;
  transform: scale(1.05);
}
.center-tiny-icons {
  line-height: initial;
  text-align: center;
  margin: 0 !important;
}

.training_info_tags {
  min-width: 9rem !important;
  color: white !important;
}

.training_info_tags_attendance {
  min-width: 6rem !important;
  color: white !important;
}

.main-content .ui.active.dimmer:not(.sm-dimmer) {
  display: flex !important;
  position: absolute !important;
  z-index: 99999999 !important;
  opacity: 0.4 !important;
  height: 100vh !important;
  padding: 0 !important;
  margin-top: -13px !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.custom-alert-message {
  display: block;
  padding: 10px 20px;
  line-height: 20px;
  font-family: monospace, Arial;
  color: white;
  font-weight: 900;
  letter-spacing: 1px;
  text-align: center;
  border-top: 6px solid teal;
  background: #9d0707;
}

.ui-alerts.top-right {
  padding: 0 !important;
  top: 57px !important;
  right: 9px !important;
  max-width: 50% !important;
}

.ui-alerts.top-right .ui.message {
  padding: 10px !important;
}

.ui.message > .close.icon {
  opacity: 0.3 !important;
}

.ui-alerts.top-right .visible.transition {
  margin-bottom: 0.8em;
}
.ui.modal .loader {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-radius: 50% !important;
}

/* .ui.modal .ui.loader {
  left: 45% !important;
} */

.ui.loader:after,
.ui.loader:before {
  border: 0 !important;
  visibility: hidden !important;
}

.ui.loader {
  transform: rotate(0deg) !important;
  margin-left: -30px !important;
  margin-top: -30px !important;
  border: 9px solid #2369b7 !important;
  border-top-color: #9ec74e !important;
  border-left-color: #9ec74e !important;
  opacity: 0.8 !important;
}

body ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px !important;
  height: 6px !important;
}

.fixed.side.menu {
  padding-bottom: 20px !important;
}

.fixed.small-side.side.menu {
  text-align: center !important;
  border-right: none !important;
  padding-bottom: 13px !important;
}

.fixed.small-side.side.menu .active.item {
  border-radius: 0 !important;
}

.table-btn-group {
  display: inline-block;
  background: #6384a9;
  border-radius: 20px;
  padding: 0 3px;
  margin: 0 5px;
}

.table-btn-group-light {
  background: #2369b7;
}
.card-title .text-muted {
  color: #fff !important;
  transform: scale(1.5) translate(-3px, 0);
}
.formio-collapse-icon + .text-muted {
  color: #fff !important;
  transform: scale(1.5) translate(-3px, 0);
}

.blue .card-header {
  background-color: #6383a8;
  color: white;
  font-weight: 700;
}

.green .card-header {
  background-color: #9ec54d;
  color: white;
  font-weight: 700;
  text-shadow: 0px 1px 4px #555;
}

.red .card-header {
  background-color: #c41f6d;
  color: white;
  font-weight: 700;
}

button.red-rheat-button {
  background-color: #f35b57 !important;
  color: "white" !important;
  border-color: #f35b57 !important;
}

button.blue-rheat-button {
  background-color: #6383a8 !important;
  color: "white" !important;
  border-color: #6383a8 !important;
}

.hover-bg-gray-100 {
  transition: all 0.3s ease;
}

.hover-bg-gray-100:hover {
  background-color: #eeeeee55;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.step-form .menu .item:not(.active) {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.monospace input {
  font-family: monospace !important;
  font-weight: 700;
}

.manual-required-asterisk {
  margin: -0.2em 0 0 0.05em;
  content: "*";
  color: #db2828;
  display: inline-block;
  vertical-align: top;
}

img.coin-icon,
.ui.label > img.coin-icon {
  width: 1.65rem !important;
  height: 1rem !important;
  color: white;
  padding-right: 5px;
  transform: scale(1.25);
  pointer-events: none;
}
