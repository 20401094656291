@import "~grapesjs/dist/css/grapes.min.css";
@import "~formiojs/dist/formio.full.css";
@import "~leaflet/dist/leaflet.css";
@import "~react-leaflet-markercluster/dist/styles.min.css";
@import "~font-awesome/scss/font-awesome";
.leaflet-container {
  height: 400px;
  width: 100%;
}
.ui.accordion .title .dropdown.icon {
  top: inherit !important;
  transform: translate(0px, 7px) !important;
}

.ui.accordion .active.title .dropdown.icon {
  transform: translate(-15px, 5px) rotate(90deg) !important;
}

.tree_acc .ui.styled.accordion .title {
  white-space: pre;
}
.tree_acc .accordion .title {
  background: #a6d1d1 !important;
  color: black !important;
}
.tree_acc .accordion .active.title {
  background: teal !important;
  color: white !important;
}
.tree_acc .accordion .title:hover {
  transform: scale(1.01);
}
.tree_acc .ui.accordion .active.content {
  border-left: 1px dotted rgb(170, 170, 170);
}
.tree_acc .ui.styled.accordion,
.tree_acc .ui.styled.accordion .accordion {
  box-shadow: none !important;
}
.tree_acc .ui.accordion .title .dropdown.icon {
  top: inherit !important;
  transform: translate(-14px, 5px) !important;
}

.ui.dropdown > .dropdown.icon {
  // transform: translate(0,-5px);
}

.ui.dropdown .display-inline {
  padding: 0 5px;
}

.ui.big.loader {
  // top: 45%;
  animation: spin 1s linear infinite !important;
}
.menu .upper-menu a.item {
  padding: 13px 7px;
}
.cover-social-media {
  height: 100px;
  min-height: 100px;
  overflow: hidden;
  position: relative;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  // background-attachment: fixed !important;
  // background-position: 0 80% !important;
  background-position: center !important;
  margin-bottom: 10px !important;
  transition: all 1s ease;
}

.cover-social-media:hover {
  min-height: 250px;
  background-position: center !important;
  // background-position: 0 10% !important;
  transition: all 1s 0.3s ease;
}

.segment.active {
  // background: #323232 !important;
  padding-bottom: 10px !important;
}

.input.small input {
  padding: 3px 10px !important;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translate(0, -5px);
  }
  100% {
    opacity: 1;
    max-height: translate(0, 0);
  }
}

.accordion .content.active.child {
  opacity: 0;
  animation: fade 0.3s ease forwards;
}

.ui.menu .ui.segment {
  padding: 0 0 0 1em !important;
}

.small-side.side .ui.segment {
  padding: 0 !important;
  text-align: center;
}

.small-side.side .content.active {
  text-align: center;
}

.side .content.active .item {
  padding-left: 13px !important;
}

.side .accordion .active.title {
  padding-left: 8px;
}

.side .ui.segment:hover {
  background: #323232;
  border-radius: 0;
}

.side .ui.segment:hover * {
  color: white !important;
}

.side .ui.segment.active * {
  color: white !important;
}

.small-side.side .ui.segment .accordion .content.active .item {
  font-size: 0.5em;
}

.small-side.side .dropdown.icon {
  opacity: 0 !important;
}

.ui.accordion .accordion .title .dropdown.icon,
.ui.accordion .title .dropdown.icon {
  position: absolute;
  top: 14px;
}

form {
  animation: fade 0.5s ease;
}

.ui.accordion .accordion {
  margin: 0 0 0 0 !important;
}

.menu .large.icon {
  opacity: 0.7;
  font-size: 1.2em;
}

.ui.modal > .content > .image {
  margin: 0 auto;
}

.ui.accordion .active.content {
  padding-top: 0 !important;
}

.loader-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #323232;
  opacity: 0.9;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ddd;
  font-weight: 700;
  font-size: 2em;
  z-index: 999999;
}

/* Grapes js fix css */
.gjs-pn-btn {
  font-size: 18px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.gjs-pn-btn:hover {
  background: #323232;
}

.gjs-pn-btn.save-btn {
  padding: 0px 10px;
}
.gjs-pn-btn.save-btn span {
  font-size: 0.7em;
}
.gjs-pn-btn.save-btn:hover {
  background: #111;
  color: limegreen;
  border-radius: 20px;
}

.peak-msg {
  position: absolute;
  top: -55px;
  z-index: 999;
  color: white;
  left: 45%;
  transform: translate(-50%, 0);
  background: #323232;
  background: #323232cc;
  padding: 5px 15px;
  border-radius: 20px;
  width: 320px;
  text-align: center;
  border: 0;
  outline: 0;
}

.no-pad-segment {
  padding: 0 !important;
  position: relative !important;
}

.content1:not(.small-content1) .no-pad-segment {
  margin-left: 10px !important;
}

/* SHARE BUTTON */
.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block;
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em;
}

.resp-sharing-button {
  border-radius: 5px;
  transition: 25ms ease-out;
  padding: 0.5em 0.75em;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top;
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle;
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none;
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
}

.resp-sharing-button--twitter:hover {
  background-color: #2795e9;
}

.resp-sharing-button--pinterest {
  background-color: #bd081c;
}

.resp-sharing-button--pinterest:hover {
  background-color: #8c0615;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
}

.resp-sharing-button--facebook:hover {
  background-color: #2d4373;
}

.resp-sharing-button--tumblr {
  background-color: #35465c;
}

.resp-sharing-button--tumblr:hover {
  background-color: #222d3c;
}

.resp-sharing-button--reddit {
  background-color: #5f99cf;
}

.resp-sharing-button--reddit:hover {
  background-color: #3a80c1;
}

.resp-sharing-button--google {
  background-color: #dd4b39;
}

.resp-sharing-button--google:hover {
  background-color: #c23321;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
}

.resp-sharing-button--linkedin:hover {
  background-color: #046293;
}

.resp-sharing-button--email {
  background-color: #777;
}

.resp-sharing-button--email:hover {
  background-color: #5e5e5e;
}

.resp-sharing-button--xing {
  background-color: #1a7576;
}

.resp-sharing-button--xing:hover {
  background-color: #114c4c;
}

.resp-sharing-button--whatsapp {
  background-color: #25d366;
}

.resp-sharing-button--whatsapp:hover {
  background-color: #1da851;
}

.resp-sharing-button--hackernews {
  background-color: #ff6600;
}
.resp-sharing-button--hackernews:hover,
.resp-sharing-button--hackernews:focus {
  background-color: #fb6200;
}

.resp-sharing-button--vk {
  background-color: #507299;
}

.resp-sharing-button--vk:hover {
  background-color: #43648c;
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--email {
  background-color: #777777;
  border-color: #777777;
}

.resp-sharing-button--email:hover,
.resp-sharing-button--email:active {
  background-color: #5e5e5e;
  border-color: #5e5e5e;
}

.resp-sharing-button--linkedin {
  background-color: #0077b5;
  border-color: #0077b5;
}

.resp-sharing-button--linkedin:hover,
.resp-sharing-button--linkedin:active {
  background-color: #046293;
  border-color: #046293;
}

.parsed-content {
  border: 1px solid #ddd;
  min-height: 250px;
  padding: 10px;
}

.parsed-content ul li p {
  display: inline;
}

.parsed-content ul li input[type="checkbox"] {
  margin-top: 6px;
  margin-right: 5px;
}

.docs-list {
  border: 1px solid #ddd;
  padding: 2px 5px 2px 5px !important;
}

.docs-list .item {
  box-shadow: 0 3px 0 0px #eee;
  padding: 5px !important;
  box-sizing: border-box;
  transition: background 0.3s ease;
}

.docs-list .item:hover {
  background: #ddd;
  border-left: 2px solid orangered;
}

@keyframes menu-slide {
  0% {
    transform: translate(100%, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.docs-menu {
  position: fixed;
  right: 0;
  top: 80px;
  bottom: 0;
  width: 30%;
  background: #eee;
  padding: 10px;
  overflow: auto;
  display: none;
  animation: menu-slide 0.5s ease forwards;
}

.ql-snow .ql-editor img {
  max-width: 160px !important;
}

/* Right Side Menu */
.rightSideMenu {
  padding: 0;
  padding-top: 6px;
  cursor: default;
  transition: all 0.3s ease !important;
}
.rightSideMenu p {
  margin: 0 !important;
  padding: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}
.rightSideMenu p.active {
  background: #445050;
}
.rightSideMenu p:hover {
  background: #1b4a88;
}

[data-tooltip]::after,
[data-tooltip]::before {
  visibility: visible !important;
}
[data-tooltip]::before {
  opacity: 1;
  position: relative;
}
[data-tooltip]::after {
  opacity: 0;
}
.ace_text-input{
  position: absolute !important;
}


[data-tooltip]:not([data-position])::before {
background-color: transparent;
}